import "../App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "../routes/root";
import Home from "../routes/Home";
import * as ReactDOM from "react-dom/client";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      children: [
        {
          path: "",
          element: <Home />,
        },
      ],
    },
  ]);
  ReactDOM.createRoot(document.getElementById("root")).render(
    <RouterProvider router={router} />
  );
}

export default App;
