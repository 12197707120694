import TypingTextBox from "../components/TypingTextBox/TypingTextBox";
import AboutMe from "../components/aboutMe/AboutMe";
import Skills from "../components/skills/Skills";
import Projects from "../components/projects/Projects";
import StandOut from "../components/standOut/StandOut";
import Mission from "../components/mission/Mission";
import Convinced from "../components/convinced/Convinced";
import ImageCarousel from "../components/imageCarousel/ImageCarousel";
import { ImageSlides } from "../components/slideObjects";
import Endorsements from "../components/endorsements/Endorsements";
import Info from "../components/info/Info";
import Footer from "../components/footer/Footer";
import Hobbies from "../components/hobbies/Hobbies";
import { useOutletContext } from "react-router-dom";

const Home = () => {
  const [sticky, dark] = useOutletContext();
  return (
    <div className={`App ${dark ? "dark" : ""}`}>
      <TypingTextBox sticky={sticky} />
      <Hobbies />
      <AboutMe />
      <Skills dark={dark} />
      <Projects dark={dark} />
      <StandOut dark={dark} />
      <Mission dark={dark} />
      <Convinced />
      <ImageCarousel slides={ImageSlides} />
      <Endorsements />
      <Info dark={dark} />
      <Footer />
    </div>
  );
};
export default Home;
