import React from "react";
import Navbar from "../components/navbar/Navbar";
import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";

const Root = () => {
  const [dark, setDark] = useState(false);
  const [sticky, setSticky] = useState(false);
  const [display, setDisplay] = useState(false);

  const checkPosition = () => {
    //nav bar location points
    if (
      Math.floor((window.scrollY / window.document.body.offsetHeight) * 100) > 6
    ) {
      setSticky(true);
    }
    if (
      Math.floor((window.scrollY / window.document.body.offsetHeight) * 100) >
      10
    ) {
      setDisplay(true);
    }
    if (
      Math.floor((window.scrollY / window.document.body.offsetHeight) * 100) <=
      6
    ) {
      setSticky(false);
      setDisplay(false);
    }

    //dark mode location points
    if (
      Math.floor((window.scrollY / window.document.body.offsetHeight) * 100) >
        15 &&
      Math.floor((window.scrollY / window.document.body.offsetHeight) * 100) <
        80
    ) {
      setDark(true);
    } else {
      setDark(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkPosition);
  }, []);
  return (
    <div className="root">
      <Navbar sticky={sticky} display={display} dark={dark} />
      <Outlet context={[sticky, dark]} />
    </div>
  );
};
export default Root;
